import remark from "remark"
import remarkHtml from "remark-html"

const mdToHtml = source => {
  return remark().use(remarkHtml).processSync(source)
}

const mdToHtmlMarkup = source => {
  return {
    __html: mdToHtml(source),
  }
}

export { mdToHtml, mdToHtmlMarkup }
