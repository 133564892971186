import React, { useContext } from "react"
import { Link } from "gatsby"
import { css } from "linaria"
import cx from "clsx"

import { NavBarContext } from "./nav-bar"

import { colors, media } from "src/styles/members"

const navLinkStyle = css`
  min-width: 8rem;
  position: relative;
  display: block;
  ${media.md.up} {
    display: inline-block;
  }
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  padding-top: 12px;
  padding-bottom: 12px;
  white-space: nowrap;
  &.active,
  &:hover {
    background-color: ${colors.primary};
  }
`

const NavLink = props => {
  const { to, children, className } = props
  const { setExpanded } = useContext(NavBarContext)
  const closeNavBar = () => {
    setExpanded(false)
  }
  return (
    <Link to={to} className={cx(navLinkStyle, className)} onClick={closeNavBar}>
      {children}
    </Link>
  )
}

const NavLinkHash = props => {
  const { to, children, className } = props
  const { setExpanded } = useContext(NavBarContext)
  const closeNavBar = () => {
    setExpanded(false)
  }
  return (
    <a href={to} className={cx(navLinkStyle, className)} onClick={closeNavBar}>
      {children}
    </a>
  )
}

export default NavLink
export { navLinkStyle, NavLinkHash }
