import React, { useEffect, useState, useCallback } from "react"
import { css } from "linaria"

import Section from "src/components/section"
import SectionHeading from "src/components/section-heading"
import Container from "src/components/container"
import { Row, Col } from "src/components/grid"
import Spinner from "src/components/spinner"
import Meal from "src/components/offer/meal"
import offerService from "src/services/offer-service"
import { HTTP_ERRORS } from "src/utils/http-client"
import { app } from "src/utils/capacitor"

const center = css`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const FoodSection = props => {
  const { id, title, subtitle } = props
  const {
    mealsUrl,
    offersUrl,
    currencyUrl,
    offersSchedulingUrl,
    offersSchedulingConfUrl,
    noMenuMeals,
    errorMenuMeals,
    offlineMenuMeals,
  } = props
  const [meals, setMeals] = useState([])
  const [currency, setCurrency] = useState("")
  const [state, setState] = useState("loading") // state: loading, loadedSuccess, networkError, error
  const updateOffer = useCallback(() => {
    offerService
      .fetchCurrentOffer({
        mealsUrl,
        offersUrl,
        currencyUrl,
        offersSchedulingUrl,
        offersSchedulingConfUrl,
      })
      .then(({ meals, currency }) => {
        setState("loadedSuccess")
        setMeals(meals)
        setCurrency(currency)
      })
      .catch(error => {
        console.error("Error fetching meals", error)
        if (error?.message === HTTP_ERRORS.NETWORK_ERROR) {
          setState("networkError")
        } else {
          setState("error")
        }
      })
  }, [
    mealsUrl,
    offersUrl,
    currencyUrl,
    offersSchedulingUrl,
    offersSchedulingConfUrl,
  ])
  useEffect(updateOffer, [updateOffer])
  useEffect(() => {
    return app.onActivate(updateOffer)
  }, [updateOffer])
  useEffect(() => {
    return app.onOnline(updateOffer)
  }, [updateOffer])
  return (
    <Section id={id}>
      <Container>
        <SectionHeading title={title} subtitle={subtitle} />
        <Row>
          {state === "loading" && (
            <Col className={center}>
              <Spinner />
            </Col>
          )}
          {state === "loadedSuccess" && meals.length === 0 ? (
            <Col className={center}>{noMenuMeals}</Col>
          ) : (
            meals.map((meal, i) => (
              <Col key={i} size={6}>
                <Meal key={meal.id} meal={meal} currency={currency} />
              </Col>
            ))
          )}
          {state === "networkError" && (
            <Col className={center}>{offlineMenuMeals}</Col>
          )}
          {state === "error" && <Col className={center}>{errorMenuMeals}</Col>}
        </Row>
      </Container>
    </Section>
  )
}

export default FoodSection
