import React from "react"
import { css } from "linaria"

import Section from "src/components/section"
import Container from "src/components/container"
import Title from "src/components/title"
import SubTitle from "src/components/subtitle"

import { colors } from "src/styles/members"
import introBgImage from "src/images/intro.jpg"

const introSection = css`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  .jump-icon {
    position: absolute;
    bottom: 60px;
    left: 50%;
    width: 4rem;
    text-align: center;
    margin-left: -2rem;
    color: ${colors.white};
    font-size: 2rem;
    animation: jump 0.5s linear alternate infinite;
    @keyframes jump {
      0% {
        transform: translate3d(0, 0, 0);
      }
      40% {
        transform: translate3d(0, 10px, 0);
      }
      100% {
        transform: translate3d(0, 30px, 0);
      }
    }
  }
`

const IntroSection = props => {
  const { id, title, subtitle } = props
  return (
    <Section id={id} className={introSection} paraImg={introBgImage} jigBottom>
      <Container>
        <Title text={title} />
        <SubTitle text={subtitle} />
      </Container>
      <a href="#menu" className="jump-icon">
        <i className="fas fa-chevron-down" />
      </a>
    </Section>
  )
}

export default IntroSection
