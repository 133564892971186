import React from "react"
import { css } from "linaria"

import AspectRatio from "src/components/aspect-ratio"

import { media } from "src/styles/members"

const mealStyle = css`
  ${media.md.up} {
    font-size: 1.25rem;
  }
  line-height: 1.2;
  position: relative;
  box-shadow: 0.1em 0.1em 0.1em 0 #222;
  .image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .price {
    position: absolute;
    top: 0;
    right: 0;
    background-color: hsla(0, 0%, 93.3%, 0.88);
    box-shadow: 0.1em 0.1em 0.1em 0 #222;
    border-radius: 50%;
    padding: 1rem;
    margin: 0.2rem;
    min-width: 4em;
    text-align: center;
    ${media.md.down} {
      padding: 0.5rem;
    }
  }
  .name {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: hsla(0, 0%, 93.3%, 0.88);
    box-shadow: 0.1em 0.1em 0.1em 0 #222;
    border-radius: 3px;
    padding: 0.3rem;
    margin: 0.2rem;
    white-space: pre;
  }
`

const formatPrice = priceStr => {
  const price = Number(String(priceStr).replace(",", "."))
  if (Number.isFinite(price)) {
    return price.toFixed(2).replace(".", ",")
  }
  return price
}

const Meal = props => {
  const { meal, currency } = props
  return (
    <AspectRatio className={mealStyle}>
      <img
        className="image"
        src={meal.imageSdUrl || meal.imageUrl}
        alt={meal.name}
      />
      <div className="price">
        {formatPrice(meal.price)} {currency}
      </div>
      <div className="name">{meal.name}</div>
    </AspectRatio>
  )
}

export default Meal
