import React from "react"
import { css } from "linaria"

import { media, colors } from "src/styles/members"

const navTogglerStyle = css`
  ${media.md.up} {
    display: none;
  }
  height: 34px;
  background-color: transparent;
  border-radius: 0.25rem;
  border: 1px solid ${colors.white};
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    background-color: ${colors.white};
    margin: 4px 8px;
  }
`

const NavToggler = props => {
  const { onClick } = props
  return (
    <button className={navTogglerStyle} onClick={onClick}>
      <span className="icon-bar" />
      <span className="icon-bar" />
      <span className="icon-bar" />
    </button>
  )
}

export default NavToggler
