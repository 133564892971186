import React from "react"
import { css } from "linaria"
import cx from "clsx"

import { colors } from "src/styles/members"

const spinnerStyle = css`
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 4px solid ${colors.black};
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner 0.75s linear infinite;
  @keyframes spinner {
    100% {
      transform: rotate(360deg);
    }
  }
`

const Spinner = props => {
  const { className } = props
  return <div className={cx(spinnerStyle, className)} />
}

const spinnerFullscreenStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`

const SpinnerFullscreen = () => {
  return (
    <div className={spinnerFullscreenStyle}>
      <Spinner />
    </div>
  )
}

export default Spinner
export { SpinnerFullscreen }
