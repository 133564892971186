import React from "react"

import Branch from "src/components/branch/branch"
import { SpinnerFullscreen } from "src/components/spinner"
import NotFoundPage from "src/pages/404"

import { useAppState } from "src/state/app-state"

const BranchPage = props => {
  const { settings, branches, isFetching } = useAppState()
  const slug = props.pageContext.slug
  const fallbackSlug = props.params.branch
  const branch = branches.find(branch =>
    [slug, fallbackSlug].includes(branch.slug)
  )
  return branch ? (
    <Branch settings={settings} branches={branches} branch={branch} />
  ) : isFetching ? (
    <SpinnerFullscreen />
  ) : (
    <NotFoundPage />
  )
}

export default BranchPage
