import React from "react"
import { css } from "linaria"
import { Link } from "gatsby"

import Section from "src/components/section"
import SectionHeading from "src/components/section-heading"
import Container from "src/components/container"
import AspectRatio from "src/components/aspect-ratio"
import { Row, Col } from "src/components/grid"
import { mdToHtmlMarkup } from "src/utils/md-to-html"

import { media, colors, stack, spacing } from "src/styles/members"

const googleMap = css`
  iframe {
    width: 100%;
    height: 100%;
  }
`
const contactInfo = css`
  text-align: center;
  ${media.md.up} {
    text-align: left;
  }
  ${stack(spacing[6])}
  h3 {
    margin-bottom: ${spacing[3]};
    font-size: ${spacing[7]};
    line-height: 1;
    i {
      margin-right: ${spacing[1]};
    }
  }
  p {
    margin: 0;
  }
  .icon {
    margin-right: 0.25rem;
  }
  a {
    color: ${colors.primary};
  }
`

const ContactSection = props => {
  const { id, title, subtitle, contacts, mapUrl, branches } = props
  const handleIframeError = e => console.log("Google map iframe error", e)
  return (
    <Section id={id}>
      <Container>
        <SectionHeading title={title} subtitle={subtitle} />
        <Row>
          <Col size={8}>
            <AspectRatio className={googleMap}>
              <iframe
                title="Google Map"
                src={mapUrl}
                width="600"
                height="450"
                frameBorder="0"
                allowFullScreen
                onError={handleIframeError}
              />
            </AspectRatio>
          </Col>
          <Col size={4}>
            {contacts.map((contact, i) => (
              <div key={i} className={contactInfo}>
                <h3>
                  <i className={contact.icon} /> {contact.title}
                </h3>
                <div
                  className="content"
                  dangerouslySetInnerHTML={mdToHtmlMarkup(contact.content)}
                />
              </div>
            ))}
            <div className={contactInfo}>
              <h3>Naše prevádzky</h3>
              <ul className="content">
                {branches.map(branch => (
                  <li key={branch.slug}>
                    <Link to={`/${branch.slug}`}>{branch.title}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default ContactSection
