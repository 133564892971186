import React from "react"
import { css } from "linaria"
import cx from "clsx"

const aspectRatioStyle = css`
  overflow: hidden;
  height: 0;
  padding-top: 62.5%;
  position: relative;
  .inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const AspectRatio = props => {
  const { className, children } = props
  return (
    <div className={cx(aspectRatioStyle, className)}>
      <div className="inner">{children}</div>
    </div>
  )
}

export default AspectRatio
