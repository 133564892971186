import React, { createContext, useState } from "react"
import { css } from "linaria"
import cx from "clsx"

import NavToggler from "./nav-toggler"

import { colors, media } from "src/styles/members"

const navbar = css`
  background-color: rgba(33, 45, 57, 0.8);
  color: ${colors.white};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  padding-top: env(safe-area-inset-top); /* fix mobile status bar */
  .nav-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    ${media.md.up} {
      display: none;
    }
    .nav-brand {
      font-size: 1.25rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      ${media.md.up} {
        font-size: 2rem;
      }
    }
  }
  .nav-menu {
    display: none;
    text-align: left;
    border-top: 1px solid #101010;
    ${media.md.up} {
      display: block;
      text-align: center;
      border: none;
    }
    &.expanded {
      display: block;
    }
  }
`

const NavBarContext = createContext()

const NavBar = props => {
  const { brand, children } = props
  const [expanded, setExpanded] = useState(false)
  const toggleNavigation = () => {
    setExpanded(!expanded)
  }
  return (
    <NavBarContext.Provider
      value={{
        expanded,
        setExpanded,
      }}
    >
      <nav className={navbar}>
        <div className="nav-main">
          <div className="nav-brand">{brand}</div>
          <NavToggler onClick={toggleNavigation} />
        </div>
        <div className={cx("nav-menu", expanded && "expanded")}>{children}</div>
      </nav>
    </NavBarContext.Provider>
  )
}

export default NavBar
export { NavBarContext }
