import React from "react"
import { css } from "linaria"

import SectionHeading from "src/components/section-heading"
import { mdToHtmlMarkup } from "src/utils/md-to-html"

const newsItem = css`
  text-align: center;
  border-bottom: 1px dotted #aeaeae;
`

const NewsItem = props => {
  const { content } = props
  return (
    <p className={newsItem} dangerouslySetInnerHTML={mdToHtmlMarkup(content)} />
  )
}

const NewsSection = props => {
  const { title, news } = props
  return (
    news.length > 0 && (
      <>
        <SectionHeading title={title} />
        <div>
          {news.map((newsItem, i) => (
            <NewsItem key={i} content={newsItem.text} />
          ))}
        </div>
      </>
    )
  )
}

export default NewsSection
