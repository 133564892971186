import httpClient from "src/utils/http-client"

const getCurrentOfferIds = ({ offersScheduling, schedulingConf }) => {
  const isActiveOffer = isActiveOfferFn(schedulingConf, new Date())
  return Object.entries(offersScheduling)
    .filter(([, offerScheduling]) => {
      if (offerScheduling.alwaysActive) {
        return true
      }
      if (isActiveOffer(offerScheduling)) {
        return true
      }
      return false
    })
    .map(([offerId]) => offerId)
}

const isActiveOfferFn = (schedulingConf, atDate) => offerScheduling => {
  if (!schedulingConf) {
    console.error("No offer scheduling conf")
    return false
  }
  if (!offerScheduling || !offerScheduling.days || !offerScheduling.hours) {
    console.error("Invalid offer scheduling object", offerScheduling)
    return false
  }
  const isDayMatched = (offerScheduling.days || "").split(",").find(day => {
    const dayConf = schedulingConf.days[day]
    return dayConf && dayConf.weekday === atDate.getDay()
  })
  const isHoursMatched = (offerScheduling.hours || "").split(",").find(hour => {
    const hourConf = schedulingConf.hours[hour]
    const from = (hourConf?.schedule?.from || "").split(":")
    const to = (hourConf?.schedule?.to || "").split(":")
    if (from.length !== 2 || to.length !== 2) {
      return false
    }
    const fromDate = new Date(atDate).setHours(from[0], from[1])
    const toDate = new Date(atDate).setHours(to[0], to[1])
    return fromDate <= atDate && atDate <= toDate
  })
  return isDayMatched && isHoursMatched
}

const getMealsForOfferIdFn = ({ offers, allMeals }) => offerId => {
  const offer = offers[offerId]
  if (!offer) {
    console.error("Missing offer", offerId)
    return []
  }
  const mealIds = Object.entries(offer?.data?.mealIds || [])
    .sort(([, orderA], [, orderB]) => orderA - orderB)
    .map(([mealId]) => mealId)
  return mealIds.flatMap(mealId => {
    const meal = allMeals[mealId]
    if (!meal) {
      console.error("Missing meal", mealId)
      return []
    }
    return [meal]
  })
}

const offerService = {
  fetchCurrentOffer({
    mealsUrl,
    offersUrl,
    currencyUrl,
    offersSchedulingUrl,
    offersSchedulingConfUrl,
  }) {
    return Promise.all([
      httpClient.fetchJson(mealsUrl),
      httpClient.fetchJson(offersUrl),
      httpClient.fetchJson(currencyUrl),
      httpClient.fetchJson(offersSchedulingUrl),
      httpClient.fetchJson(offersSchedulingConfUrl),
    ]).then(
      ([allMeals, offers, currency, offersScheduling, schedulingConf]) => {
        const mealsForOfferId = getMealsForOfferIdFn({ allMeals, offers })
        const meals = getCurrentOfferIds({
          offersScheduling,
          schedulingConf,
        }).flatMap(mealsForOfferId)
        return {
          meals,
          currency,
        }
      }
    )
  },
}

export default offerService
