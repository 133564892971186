import React from "react"
import { Link } from "gatsby"
import { css } from "linaria"
import cx from "clsx"

import { navLinkStyle } from "./nav-link"
import { media } from "src/styles/members"
import logo from "src/images/logo.png"

const navLinkBrandStyle = css`
  display: none;
  ${media.md.up} {
    display: inline-block;
  }
  min-width: 8.3rem;
  padding-bottom: 18px;
  img {
    position: absolute;
    padding: 0.6rem;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    border: 1px solid gray;
    background-color: white;
  }
`

const NavLinkBrand = props => {
  const { to, className } = props
  return (
    <Link to={to} className={cx(navLinkStyle, navLinkBrandStyle, className)}>
      <img src={logo} alt="Dobrá BAŠTA" />
    </Link>
  )
}

export default NavLinkBrand
