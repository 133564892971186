import React from "react"
import { css } from "linaria"
import cx from "clsx"

import { navLinkStyle } from "./nav-link"
import { media, spacing } from "src/styles/members"

const navDropdownStyle = css`
  &.active .nav-dropdown,
  &:hover .nav-dropdown {
    display: flex;
  }
  .nav-dropdown {
    ${media.md.up} {
      display: none;
    }
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
    background-color: rgba(33, 45, 57, 0.8);
    text-align: left;
    ${media.md.down} {
      padding-left: ${spacing[4]};
    }
  }
`

const NavDropdown = props => {
  const { title, children, className } = props
  return (
    <div className={cx(navLinkStyle, navDropdownStyle, className)}>
      {title}
      <div className="nav-dropdown">{children}</div>
    </div>
  )
}

export default NavDropdown
