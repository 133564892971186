import React from "react"

import Layout from "src/components/layout"
import SEO from "src/components/seo"
import NavBar, {
  NavLink,
  NavLinkHash,
  NavLinkBrand,
  NavDropdown,
} from "src/components/navbar"
import IntroSection from "src/components/branch/intro-section"
import FoodSection from "src/components/branch/food-section"
import AboutUsSection from "src/components/branch/about-us-section"
import NewsSection from "src/components/branch/news-section"
import ContactSection from "src/components/branch/contact-section"
import Footer from "src/components/footer"

const Branch = props => {
  const { settings, branches, branch } = props
  const {
    title,
    sectionIntro,
    sectionFoodMenu,
    sectionAboutUs,
    sectionNews,
    sectionContacts,
    urlSettings,
  } = branch
  return (
    <Layout cookiesConsent={settings.cookiesConsent}>
      <SEO
        title={settings.title}
        subtitle={title}
        desription={settings.desription}
      />
      <NavBar brand={`${settings.title} - ${title}`}>
        <NavLinkHash to="#menu">Menu</NavLinkHash>
        <NavLinkHash to="#o-nas">O nás</NavLinkHash>
        <NavLinkBrand to="/" />
        <NavLinkHash to="#kontakt">Kontakt</NavLinkHash>
        <NavDropdown title="Prevádzky">
          {branches.map(branch => (
            <NavLink key={branch.slug} to={`/${branch.slug}`}>
              {branch.title}
            </NavLink>
          ))}
        </NavDropdown>
      </NavBar>

      <IntroSection
        id="intro"
        title={sectionIntro.title}
        subtitle={sectionIntro.subtitle}
      />

      <FoodSection
        id="menu"
        title={sectionFoodMenu.title}
        subtitle={sectionFoodMenu.subtitle}
        mealsUrl={urlSettings.mealsUrl}
        offersUrl={urlSettings.offersUrl}
        currencyUrl={urlSettings.currencyUrl}
        offersSchedulingUrl={urlSettings.offersSchedulingUrl}
        offersSchedulingConfUrl={urlSettings.offersSchedulingConfUrl}
        noMenuMeals={settings.noMenuMeals}
        errorMenuMeals={settings.errorMenuMeals}
        offlineMenuMeals={settings.offlineMenuMeals}
      />

      <AboutUsSection
        id="o-nas"
        title={sectionAboutUs.title}
        subtitle={sectionAboutUs.subtitle}
        features={sectionAboutUs.features}
      >
        <NewsSection
          title={sectionNews.title}
          subtitle={sectionNews.subtitle}
          news={sectionNews.news}
        />
      </AboutUsSection>

      <ContactSection
        id="kontakt"
        title={sectionContacts.title}
        subtitle={sectionContacts.subtitle}
        contacts={sectionContacts.contact}
        mapUrl={urlSettings.mapUrl}
        branches={branches}
      />

      <Footer
        siteTitle={settings.title}
        branchTitle={title}
        googlePlayStoreUrl={settings.googlePlayStoreUrl}
        appleAppStoreUrl={settings.appleAppStoreUrl}
      />
    </Layout>
  )
}

export default Branch
