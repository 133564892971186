import React from "react"
import { css } from "linaria"

import Section from "src/components/section"
import SectionHeading from "src/components/section-heading"
import Container from "src/components/container"
import { Row, Col } from "src/components/grid"

import { colors } from "src/styles/members"
import parallax1 from "src/images/parallax1.jpg"

const featureClass = css`
  text-align: center;
  .feature-title {
    font-size: 1.5rem;
    margin-top: 0.5rem;
  }
  .feature-icon i {
    font-size: 60px;
  }
`

const aboutUsSectoin = css`
  color: ${colors.white};
`

const Feature = props => {
  const { feature } = props
  return (
    <div className={featureClass}>
      <div className="feature-icon">
        <i className={feature.icon} />
      </div>
      <h3 className="feature-title">{feature.title}</h3>
      {feature.content}
    </div>
  )
}

const AboutUsSection = props => {
  const { id, title, subtitle, features, children } = props
  return (
    <Section
      id={id}
      paraImg={parallax1}
      className={aboutUsSectoin}
      jigTop
      jigBottom
    >
      <Container>
        <SectionHeading title={title} subtitle={subtitle} />
        <Row>
          {features.map((feature, i) => (
            <Col key={i}>
              <Feature feature={feature} />
            </Col>
          ))}
        </Row>
        <br />
        <br />
        {children}
      </Container>
    </Section>
  )
}

export default AboutUsSection
