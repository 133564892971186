import React from "react"
import cx from "clsx"
import { css } from "linaria"

import { media } from "src/styles/members"

const row = css`
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem;
  ${media.md.up} {
    margin: -1rem;
  }
`

const Row = props => {
  const { children, className } = props
  return <div className={cx(row, className)}>{children}</div>
}

const col = css`
  padding: 0.5rem;
  ${media.md.up} {
    padding: 1rem;
  }
  flex: 1 1 100%;
  ${media.md.up} {
    flex: 1 1 0%;
    ${[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
      .map(
        i => `
        &.col-${i} {
          flex: 0 1 100%;
          ${media.md.up} {
            flex: 0 1 ${(100 / 12) * i}%;
          }
        }
      `
      )
      .join("")}
  }
`

const Col = props => {
  const { size, children, className } = props
  return <div className={cx(col, `col-${size}`, className)}>{children}</div>
}

export { Row, Col }
