import React from "react"
import { css } from "linaria"

import { media, spacing } from "src/styles/members"

const sectionHeading = css`
  text-align: center;
  font-size: ${spacing[8]};
  ${media.md.up} {
    font-size: ${spacing[9]};
  }
  text-transform: uppercase;
`

const sectionSubHeading = css`
  text-align: center;
  font-size: ${spacing[5]};
  ${media.md.up} {
    font-size: ${spacing[6]};
  }
  text-transform: uppercase;
  position: relative;
  padding-top: 0.4rem;
  padding-bottom: 1.1rem;
  margin-top: 0rem;
  margin-bottom: 2rem;
  &:after {
    content: "";
    position: absolute;
    background: #ed0121;
    width: 80px;
    height: 3px;
    bottom: 0px;
    left: 50%;
    margin-left: -40px;
  }
`

const SectionHeading = props => {
  const { title, subtitle } = props
  return (
    <div>
      <h2 className={sectionHeading}>{title}</h2>
      <p className={sectionSubHeading}>{subtitle}</p>
    </div>
  )
}

export default SectionHeading
